import { Route } from '@angular/router';
import { NavigationComponent } from '@lib/frontend-student-navigation-feature-navigation';
import {
    createIsLoggedInAndActiveGuard,
    createNotLoggedInOrActiveGuard,
} from '@lib/frontend-shared-accounts-data-accounts-service';
import { NamedMediaLibrary } from '@lib/shared-interface-media';

const isLoggedIn = createIsLoggedInAndActiveGuard('/account/login');
const notLoggedIn = createNotLoggedInOrActiveGuard('/dashboard');

export const APP_ROUTES: Route[] = [
    {
        path: '',
        component: NavigationComponent,
        canActivate: [isLoggedIn],
        children: [
            {
                path: 'assignments',
                title: 'My Assignments',
                loadComponent: () =>
                    import('@lib/frontend-student-assignments-feature-assignment-list-page'),
            },
            {
                path: 'assignments/assessment/:studentAssignmentId',
                title: 'Assessment',
                loadComponent: () =>
                    import('@lib/frontend-student-assignments-feature-assessment-page'),
            },
            {
                path: 'assignments/miscellaneous/:studentAssignmentId',
                title: 'Assignment',
                loadComponent: () =>
                    import('@lib/frontend-student-assignments-feature-miscellaneous-page'),
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                loadComponent: () =>
                    import('@lib/frontend-student-dashboard-feature-dashboard-page'),
            },
            {
                path: 'videos',
                title: 'Videos',
                loadComponent: () => import('@lib/frontend-shared-feature-media-library-list-page'),
                data: {
                    library: NamedMediaLibrary.STUDENT,
                },
            },
            {
                path: 'videos/:id',
                title: 'Video Details',
                loadComponent: () =>
                    import('@lib/frontend-shared-feature-media-library-detail-page'),
                data: {
                    library: NamedMediaLibrary.STUDENT,
                },
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    // Todo: Implementation a wrapper component similar to the school app.
    {
        path: 'account/login',
        title: 'Student Login',
        canActivate: [notLoggedIn],
        loadComponent: () => import('@lib/frontend-student-authentication-feature-login-page'),
    },
    {
        // Redirect unrecognized routes to the dashboard if the user is logged in,
        // otherwise redirect to the login page.
        // This can't be used in a group with the isLoggedIn guard,
        // as it will cause a redirect loop.
        path: '**',
        redirectTo: '',
    },
];
