import { Component } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { addIcons } from 'ionicons';

@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [IonicModule],
    providers: [
        // This might be possible at the app providers level
        // but the following article shows it in component providers:
        // https://ionic.zendesk.com/hc/en-us/articles/10386373742231-Angular-Standalone-Components-with-Ionic
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
    ],
})
export class AppComponent {
    public constructor() {
        // Icon names are kebab-case
        /* eslint-disable @typescript-eslint/naming-convention */
        addIcons({
            'font-size': '/assets/icons/font-size-icon.svg',
        });
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}
