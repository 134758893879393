<lib-branded-navigation [includeSideMenu]="false">
    <!-- Without tabindex="" this requires two tabs to actually focus the correct element -->
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/assignments"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        <ion-label>
            {{ 'shared.assignments.myAssignments' | translate }}
        </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerDirection="root"
        routerLink="/videos"
        routerLinkActive="selected"
        shape="round"
        tabindex=""
    >
        <ion-label>
            {{ 'shared.videos.videos' | translate }}
        </ion-label>
    </ion-button>

    <ion-tab-button mobileTabButton tab="assignments">
        <ion-label>
            {{ 'shared.navigation.mobile.assignments' | translate }}
        </ion-label>
        <ion-icon name="document-text-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="dashboard">
        <picture>
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-only-black.svg"
            />
            <img alt="Logo" class="logo-icon" src="/assets/logos/logo-icon-only-black.svg" />
        </picture>
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="videos">
        <ion-label>
            {{ 'shared.videos.videos' | translate }}
        </ion-label>
        <ion-icon src="/assets/icons/video_library.svg" />
    </ion-tab-button>

    <ion-button accountMenuToggle>
        <ion-avatar slot="icon-only">
            <ion-icon name="person-circle-outline" />
        </ion-avatar>
    </ion-button>

    <ion-avatar accountMenuToolbarContent slot="start">
        <ion-icon name="person-circle-outline" />
    </ion-avatar>
    <ion-title accountMenuToolbarContent>
        <div>{{ accountTitle$ | async }}</div>
        <small>Student ID: {{ accountStudentId$ | async }}</small>
    </ion-title>

    <ion-menu-toggle accountMenuContent menu="account-menu">
        <ion-list lines="none" class="ion-no-padding">
            @if (isLoggedIn$ | async) {
                <ion-item (click)="logOut()" button="" detail="false" lines="full">
                    <ion-label> {{ 'shared.navigation.account.logout' | translate }}</ion-label>
                    <ion-icon slot="end" name="log-out-outline" />
                </ion-item>
            } @else {
                <ion-item
                    button=""
                    detail="false"
                    lines="full"
                    routerLink="account/login"
                    routerLinkActive="selected"
                >
                    <ion-label> {{ 'shared.navigation.account.login' | translate }}</ion-label>
                    <ion-icon slot="end" src="assets/icons/login.svg" />
                </ion-item>
            }
        </ion-list>
    </ion-menu-toggle>
</lib-branded-navigation>
